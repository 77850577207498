html {
  background-color: #1D1D1D;
}
body {
  font-family: Saira, Arial, sans-serif;
  background-color: #F1F1F1 !important;
  margin: 0 !important;
}
a {
  color: #1D1D1D;
  text-decoration: none;
}
a.blue {
  color: #3797F0 !important;
}
a:visited {
  color: #1D1D1D;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #3797F0;
}

.App {
  width: 100%;
  overflow: hidden;
}

img {
  width: 100%;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.hp-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1f0744;
  color: white;
  padding: 95px 18px;
  position: relative;
}

.hp-block.full {
  height: 100vh;
  background-color: transparent;
}

.hp-block ul {
  text-align: left;
  padding-bottom: 24px;
}

.hp-block h3 {
  margin-bottom: 54px;
}

.hp-block .MuiTypography-h3  {
  margin-bottom: 65px;
}

.App {
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}

body {
  background-color: #F1F1F1;
}

.background-container{
  position: fixed;
  top: 0;
  left:0;
  bottom: 0;
  right: 0;
}

.stars {
  background: black url("./assets/stars.png") repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 0;
}

.twinkling{
  width:10000px;
  height: 100%;
  background: transparent url("./assets/twinkling.png") repeat;
  background-size: 1000px 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;

  -moz-animation:move-background 70s linear infinite;
  -ms-animation:move-background 70s linear infinite;
  -o-animation:move-background 70s linear infinite;
  -webkit-animation:move-background 70s linear infinite;
  animation:move-background 70s linear infinite;

}

.footer-wrapper {
  background-color: #1D1D1D;
}

a.foot-link {
  color: #f1f1f1;
  margin-right: 16px;
  margin-bottom: 12px;
}

.carousel .control-dots {
  text-align: left !important;
}

.carousel .control-dots .dot {
  width: 16px !important;
  height: 16px !important;
  box-shadow: none !important;
  background-color: #c7c7c7 !important;
}

.carousel .control-dots .dot.selected {
  background-color: #6b6b6b !important;
}
.carousel .hp-slide {
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 41.67%;
}
@media (max-width: 600px) {
  .carousel .hp-slide {
    padding-top: 100%;
  }
}
.carousel .hp-slide img {
  position: absolute;
  left: 0;
  top: 0;
}
.carousel .hp-slide a.banner-link {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.carousel .hp-slide .inner-content {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 2;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .hp-slide .inner-content h3{
  white-space: normal;
}

.stripe-box {
  border: thin solid #b9b9b9;
  padding: 13px 13px;
  border-radius: 5px;
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
  font-family: Saira !important;
}

.doodle-circle {
  border-top-left-radius: 52%;
  border-top-right-radius: 53%;
  border-bottom-left-radius: 51%;
  border-bottom-right-radius: 52%;
  width: 100%;
  position: relative;
  background-color: #d5d5d5;
  padding-top: 100%;
  display: inherit;
}
.doodle-circle  img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
input.secret {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  cursor: pointer;
  left: 0;
  background-color: blueviolet;
  z-index: 9;
}
.scrolling-wrapper-container {
  height: 360px;
  overflow: hidden;
  width: 100%;
}
.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 380px;
}

.scrolling-wrapper .card {
  flex: 0 0 auto;
  text-align: center;
  padding-right: 15px;
  width: 275px;
}

@media (max-width: 767px) {
  .scrolling-wrapper-container {
    height: 290px;
  }
  .scrolling-wrapper .card {
    width: 220px;
  }
  .search-bar input {
    height: 1.9375em;
  }
}

.carousel .control-dots {
  bottom: -50px !important;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.status-fulfilled {
  color: #4BD964;
}

.status-pending {
  color: #6B6B6B;
}

.status-declined {
  color: #FF5D4F;
}

.status-cancelled {
  color: #FF5D4F;
}
.status-expired {
  color: #FF5D4F;
}
.blue-links a {
  color: #3797F0;
}
.search-bar-wrapper {
  width: 100%;
  max-width: 420px;
  margin-bottom: 18px;
}
.search-bar {
  background-color: rgb(241, 241, 241);
  overflow: hidden;
  border-radius: 7px;
  padding-left: 10px !important;
}
.profile-name {
  position: relative;
}
.profile-name img.doodler-badge {
  position: absolute;
  right: -29px;
  top: calc(50% - 12px);
}
.doodle-image-container {
  position: relative;
  padding-top: 100%;
  background-color: #d5d5d5;
}
.doodle-image-container img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.hiw-circle {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #1D1D1D;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.grad-button {
  background-image: linear-gradient(to right, #1fa5c1 0%, #3bc4aa 100%, #1fa5c1 0%);
  border-radius: 13px;
  box-sizing: border-box;
  color: #31bab3;
  display: block;
  height: 43px;
  margin: 0 auto;
  padding: 2px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}
.grad-button:hover {
  color: #fff;
}
.grad-button span {
  align-items: center;
  background: #f1f1f1;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: background .5s ease;
  width: 100%;
}

.grad-button:hover span {
  background: transparent;
}

.grad-button.badge {
  background-image: linear-gradient(to right, #1fa5c1 0%, #3bc4aa 100%);
  width: 50px;
  height: 25px;
  padding: 1px;
}

.grad-button.badge span {
  font-size: 12px;
}
@media (max-width: 600px) {
  .grad-button.badge {
    width: 40px;
  }
  .grad-button.badge span {
    font-size: 10px;
  }
}

.app-header-avatar.wallet-connected img {
  border-radius: 50%;
}

.app-header-avatar.wallet-connected {
  padding: 2px;
  background-image: linear-gradient(to right, #1fa5c1 0%, #3bc4aa 100%);
}

.path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 15s linear alternate forwards;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.doodle-title {
  line-height: 13px;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ab-text-container {
  max-width: 480px;
}

.about-section {
  min-height: 600px;
  padding-top: 75px;
}
.drawer-content {
  color: #f1f1f1;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 18px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.drawer-content .MuiTypography-h3  {
  color: #F1F1F1;
  font-size: 32px;
}
.css-1831tj0-MuiModal-root-MuiDrawer-root {
  z-index: 10000 !important;
}
